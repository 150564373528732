<template>
  <v-container fluid>
    <GsActivityLog />
  </v-container>
</template>

<script>
import GsActivityLog from "@/components/GsActivityLog";

export default {
  name: "ActivityLog",
  components: {
    GsActivityLog
  },
  data() {
    return {};
  }
};
</script>
