<template>
  <v-card fluid>
    <GsDataTable
      ref="table"
      api="email_manager_api"
      endpoint="/jobs"
      :appSetting="appSettingKey"
      :headers="headers"
      :sort-by="['createdAt']"
      :sort-desc="[true]"
      :beforeCallApi="beforeCallApi"
      :afterCallApi="afterCallApi"
      :single-expand="singleExpand"
      show-expand
      item-key="jobId"
    >
      <template v-slot:item.templateId="{ item }">
        <div>
          <button @click="navigateToTemplateDetails(item)">
            {{ item.$calculated.$templateName }}
          </button>
        </div>
      </template>

      <template v-slot:item.createdAt="{ item }">
        <span>{{ item.createdAt | getDisplayDate }}</span>
        <br />
        <span> ({{ item.$calculated.$createdBy | empty }})</span>
      </template>

      <template v-slot:item.updatedAt="{ item }">
        <span>{{ item.updatedAt | getDisplayDate }}</span>
      </template>

      <template v-slot:item.inputValues="{ item }">
        <div class="my-3 object-box">
          {{ displayInputValues(item.inputValues) | shortenText(200) }}
        </div>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip :color="getStatusColor(item.status)" dark>{{
          $t(item.status.split(".").pop())
        }}</v-chip>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="grey lighten-4 py-4">
          <GsMessageLog
            :appSettingKey="null"
            :jobId="item.jobId"
            extraClass="grey lighten-4"
            :flat="true"
            :tile="true"
          />
        </td>
      </template>
    </GsDataTable>
  </v-card>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import GsMessageLog from "@/components/GsMessageLog";
import { GsDataTable } from "ngt-frontend-core";

export default {
  name: "ActivityLog",
  components: {
    GsMessageLog,
    GsDataTable
  },
  props: {
    jobId: {
      type: String,
      default: null
    },
    templateId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      appSettingKey: "activity",
      headers: [
        {
          text: this.$t("createdAt"),
          value: "createdAt",
          sortable: true,
          width: "20%"
        },
        {
          text: this.$t("templateId"),
          value: "templateId",
          sortable: true,
          width: "20%"
        },
        {
          text: this.$t("inputValues"),
          value: "inputValues",
          sortable: false,
          width: "30%"
        },
        {
          text: this.$t("status"),
          value: "status",
          sortable: true,
          width: "10%",
          align: "center"
        },
        {
          text: this.$t("updatedAt"),
          value: "updatedAt",
          sortable: true,
          width: "15%"
        },
        {
          text: "",
          value: "data-table-expand",
          sortable: false,
          width: "5%",
          align: "right"
        }
      ],
      expanded: [],
      singleExpand: true,
      initialized: false
    };
  },
  watch: {},
  mounted() {
    this.initialized = false;
    this.$nextTick(() => {
      this.initialized = true;
    });
  },
  methods: {
    async refreshTable() {
      try {
        await this.$refs.table.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async beforeCallApi(params) {
      if (this.jobId != null) {
        params.url += `&filter=jobId:eq:${this.jobId}`;
      }

      if (this.templateId != null) {
        params.url += `&filter=templateId:eq:${this.templateId}`;
      }
      params.url += `&fields=jobId,templateId,status,inputValues,expiresAt,createdAt,createdBy,updatedAt`;
      params.url += `&r8sFields=createdBy.name,updatedBy.name`;
      return params;
    },
    async afterCallApi(params) {
      const templateIds = params.items.map(p => p.templateId);
      const templateNames = await this.getTemplateNamesByIds(templateIds);
      for (const item of params.items) {
        item.$calculated = item.$calculated || {};
        item.$calculated.$createdBy = item.createdBy?.name;
        item.$calculated.$updatedBy = item.updatedBy?.name;
        item.$calculated.$templateName = templateNames[item.templateId]?.name;
      }
      return params;
    },
    async getTemplateNamesByIds(templateIds) {
      try {
        const ids = templateIds.map(p => encodeURIComponent(p)).join(",");
        const result = await callAPI({
          url: `${this.$store.state.email_manager_api}/templates?filter=templateId:in:[${ids}]&fields=name`,
          method: "GET",
          cache: true
        });
        return this.arrToHash(result.data, "templateId");
      } catch (error) {
        console.log(error);
      }
    },
    // async getTemplateDetailsById(templateId) {
    //   try {
    //     const result = await callAPI({
    //       url: `${this.$store.state.email_manager_api}/templates/${templateId}`,
    //       method: "GET",
    //       cache: true
    //     });
    //     if (!result) {
    //       return {
    //         templateId: "",
    //         templateName: "",
    //         templateDescription: "",
    //         templateUrl: ""
    //       };
    //     }
    //     return {
    //       templateId: result.data.templateId,
    //       templateName: result.data.name,
    //       templateDescription: result.data.description,
    //       templateUrl: `/admin/templates/${result.data.templateId}`
    //     };
    //   } catch (error) {
    //     console.log(error);
    //     // this.errors.push(error)
    //     return {
    //       templateId: "",
    //       templateName: "",
    //       templateDescription: "",
    //       templateUrl: ""
    //     };
    //   }
    // },
    navigateToTemplateDetails(item) {
      this.$router.push({
        name: "Manage Template Details",
        params: { templateId: item.templateId }
      });
    },
    displayInputValues(inputValues) {
      const str = JSON.stringify(inputValues);
      return str;
    }
  }
};
</script>

<style scoped>
.object-box {
  font-size: 11px;
  line-height: 20px;
}
</style>
